<template>
  <div>
    <Main style="margin-top: 2rem">
      <a-row v-if='loading'>
        <a-col :xxl="24" :lg="24" :md="24" :xs="23"  class="box-event">
          <div  class='justify-content-center align-items-center' >
            <a-spin />
          </div>
        </a-col>

      </a-row>

      <a-row v-else :gutter="25" style="margin-bottom: 1rem" >
        <a-col :xxl="24" :lg="24" :xs="24" style="padding: 2rem">
          <general-information
            :generalForm="eventForm.generalInfo"
            :eventForm="eventForm"
            :sendForm="submitStatus.generalInfo"
            v-on:getFormValues="handleCheckFormsToValidEvent"

          />
          <AddFiles
            :files="eventForm.files"
            :sendForm="submitStatus.files"
            :eventForm="eventForm"
            v-on:getFormValues="handleCheckFormsToValidEvent"
          />
        </a-col>
        <a-col :xxl="24" :lg="24" :md="24" :xs="24">
          <a-row :gutter="25" style="text-align: center;">
            <a-col :xxl="11" :lg="11" :md="11" :xs="11" class="mt-4">
              <button class="btn-outlined-exit" @click="handleCancelEditEvent">
                <span class="title-normal"> Cancelar </span>
              </button>
            </a-col>
            <a-col :xxl="13" :lg="13" :md="13" :xs="13" class="mt-4">
              <button class="btn-fill-add" v-on:click="handleSubmit">
                <span class="title-normal"> {{ isValid ?  'Actualizar evento' : 'Mandar a Validación' }}</span>
              </button>
            </a-col>
          </a-row>
        </a-col>

      </a-row>

    </Main>
  </div>
</template>

<script>
import { Main } from '@/view/styled.js';
import { defineAsyncComponent, reactive, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Notification } from 'ant-design-vue';
import {getItem} from '@/utility/localStorageControl';
import { editEvent, getEvent } from '@/services/events';

const AddFiles = defineAsyncComponent(() => import('./overview/create/FileContainer'));
const GeneralInformation = defineAsyncComponent(() => import('./overview/edit/GeneralInformation'));

export default {
  name: 'EditView',
  components: {
    Main,
    GeneralInformation,
    AddFiles,
  },
  data(){
    return{
      event: {},
      isValid: null,
      loading: false,
    }
  },
  mounted() {
    this.getEventDetail()
  },

  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const date = new Date();
    const userRol = reactive(getItem('roles'))
    const today =
      date.getDate() + '/' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();

    dispatch('changeTitleHeader', 'Editar un evento');


    const eventForm = reactive({
      generalInfo: {
        title: '',
        grouped_events: 0,
        event_date: '',
        event_hour: '',
        end_date: '',
        end_time: '',
        type: '',
        edit_date: today,
        start_register_date: null,
        end_register_date: null,
        description: '',
        amount_participants: '',
      },
      files: [],
    });

    const submitStatus = reactive({
      loading: false,
      generalInfo: false,
      files: false,
    });

    const formStatus = reactive({
      generalInfo: undefined,
      files: undefined,

    });

    const formStatusOthers = reactive({
      generalInfo: undefined,
    })



    const saveDataEditValidEvent = () => {
      //call emit by prop change on components

      if(eventForm.generalInfo.type == 'other'){
        formStatusOthers.generalInfo = undefined;
        submitStatus.generalInfo = !submitStatus.generalInfo;
      } else{
        formStatus.generalInfo = undefined;
        formStatus.files = undefined;
        submitStatus.generalInfo = !submitStatus.generalInfo;
        submitStatus.files = !submitStatus.files;
      }

    };


    return {
      router,
      saveDataEditValidEvent,
      eventForm,
      submitStatus,
      formStatus,
      userRol,
      formStatusOthers,

    };
  },
  methods: {
    handleCheckFormsToValidEvent: function(values) {

      let formStatus = {};
      if(this.eventForm.generalInfo.type == "other"){
        formStatus = this.formStatusOthers;
      }else{
        formStatus = this.formStatus
      }


      formStatus[values.typeForm] = values.is_ok;
      const thereIsError = Object.values(formStatus).includes(false);
      const finishedValidate = !Object.values(formStatus).includes(undefined);
      console.log(formStatus)
      if (thereIsError && finishedValidate) {
        Notification['error']({
          message: 'Error',
          description: 'Complete el formulario',
        });
      }
      if (!thereIsError && finishedValidate) {
        const data = {
          ...this.eventForm.generalInfo,
          files:[],
          old_files: []
        };
        const oldFiles = [];
        const newFiles = [];

        this.eventForm.files.forEach(value=>{
          if(value instanceof File){
            newFiles.push(value);
          }else{
            oldFiles.push(value);
          }
        })
        data.old_files= oldFiles;
        data.files  = newFiles ;

        this.submitStatus.loading = true;
        const form = new FormData( )
        this.formatData(form, data);
        console.log(data);
        form.append('_method', 'PUT')
        editEvent(this.eventForm.id, form)
          .then((response) => {
            this.submitStatus.loading = false;
            if (response.success) {

              this.showNotificationAndRedirect('success', 'Éxito', 'Tu evento ha sido actualizado exitosamente');
            } else {
              this.showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
            }
          })
          .catch(() => {
            //console.log("error", error)
            this.showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
          });
      }
    },

    showNotificationAndRedirect: function (type, message, description) {
      Notification[type]({
        message,
        description,
      });
      if (type == 'success') {
        this.router.push('/');
        /*setTimeout(() => {
            this.router.push('/');
        }, 5000);*/
      }
    },
    formatData: function (form, data, prefix = '') {
      for (let key in data) {
        if ((typeof data[key] === 'object') && !(data[key] instanceof  File)) {
          this.formatData(form, data[key], `${prefix}${key}${prefix ? ']' : ''}[`);
        } else {
          if(typeof data[key] === 'boolean') data[key] = data[key] ? 1 : 0;
          form.append(`${prefix}${key}${prefix ? ']' : ''}`, data[key]);
        }
      }
    },
    async getEventDetail (){
      this.loading=true
      const eventId =useRoute().params.id
      await getEvent(eventId)
        .then((response)=>{
          if(response.success){
            const { event } = response
            this.isValid = event.status!= 'No aprobado'
            if(!this.isValid){this.$router.push({name: 'editNotValidEvent', params: { id: eventId }});}
            this.eventForm.id = event.id

            this.eventForm.files = JSON.parse(event.files) || []
            Object.keys(this.eventForm.generalInfo).forEach(key=>{
              if(event[key]){
                this.eventForm.generalInfo[key] = event[key];
              }

            })


          }
        })
        .catch((e)=>{
          console.log(e)
        })
      this.loading=false
    },
    handleCancelEditEvent(){
      this.$router.go(-1);
    },
    handleSubmit(){
      this.saveDataEditValidEvent();
    }

  },
};
</script>

<style lang='sass'>
.events-activities
  background-color: #FFFFFF
  border-top: 1px solid #CCCCCC

.btn-fill-add
  border: 1px solid #3FA7E1
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 7px
.ant-time-picker-input
  border-radius: 30px !important
.ant-form-item-required::before
  color: #ffff !important
</style>
